/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
	      autoPlayYouTubeModal();
		  function autoPlayYouTubeModal() {
		      var trigger = $("body").find('[data-toggle="modal"]');
		      trigger.click(function () {
		          var theModal = $(this).data("target"),
		              videoSRC = $(this).attr("data-theVideo"),
		              videoSRCauto = videoSRC + "?autoplay=1";
		          $(theModal + ' iframe').attr('src', videoSRCauto);
		          $(theModal + ' button.close').click(function () {
		              $(theModal + ' iframe').attr('src', videoSRC);
		          });
		      });
		  }  
		  var originalLeave = $.fn.popover.Constructor.prototype.leave;
		  $.fn.popover.Constructor.prototype.leave = function(obj){
		  var self = obj instanceof this.constructor ?
		  obj : $(obj.currentTarget)[this.type](this.getDelegateOptions()).data('bs.' + this.type);
		  var container, timeout;
		  originalLeave.call(this, obj);
		  if(obj.currentTarget) {
		    container = $(obj.currentTarget).siblings('.popover');
		    timeout = self.timeout;
		    container.one('mouseenter', function(){
		      clearTimeout(timeout);
		      container.one('mouseleave', function(){
		        $.fn.popover.Constructor.prototype.leave.call(self, self);
		      });
		    });
		  }
		};
		
		$('.modal-thumb').each( function () {
		    img = new Image();
		    img.src = $(this).data('modal-src');
		});
		
		$('.modal-thumb').on('click', function () {
		    var title = $(this).data('modal-title'),
		        src = $(this).data('modal-src');
		    
		    $('#modal h4').text(title);
		    $('#modal img').attr('src', src);
		
		    $('#modal').modal('show');
		});
		$(".jumper").on("click", function( e ) {
		    e.preventDefault();
		    $("body, html").animate({ 
		      scrollTop: $( $(this).attr('href') ).offset().top 
		    }, 600);
		  });
		$('#flexProducts').each(function(){  
	        var highestBox = 0;
	
	        $(this).find('.losProduct').each(function(){
	            if($(this).height() > highestBox){  
	                highestBox = $(this).height();  
	            }
	        })
	
	        $(this).find('.losProduct').height(highestBox);
	    });
		$('body').popover({
			selector: '[data-popover]',
			trigger: 'click',
			delay: {show: 120, hide: 120}
		});
	    jQuery(function($){
			$('.navbar-toggle').click(function(){
				$('.navbar-collapse').toggleClass('right');
				$('.navbar-toggle').toggleClass('indexcity');
    		});
		});
		(function() {
			  var $window = $(window),
			  flexslider = { vars:{} };
			  function getGridSize() {
			    return (window.innerWidth < 400) ? 2 :
			           (window.innerWidth < 600) ? 2 : 3;
			  }
			  function getGridSizeSlider() {
			    return (window.innerWidth < 400) ? 1 :
			           (window.innerWidth < 768) ? 1 : 9;
			  }
			  $window.load(function() {
			    $('.flexslider').flexslider({
			    	animation: "slide",
			    	controlNav: "thumbnails",
			    	animationLoop: false,
			    	slideshow: false,
					directionNav: false,
					minItems: getGridSizeSlider(),
					maxItems: getGridSizeSlider()
			  	});
			  	$('.flexsliderKlanten').flexslider({
			    	animation: "slide",
			    	animationLoop: false,
			    	directionNav: false,
			    	selector: ".slides > .slideItem",
					slideshow: false,
					itemWidth: 50,
					itemMargin: 15,
					minItems: getGridSize(),
					maxItems: getGridSize()
	  			});
	  			$('.flexsliderBranche').flexslider({
			    	animation: "slide",
			    	animationLoop: false,
			    	directionNav: false,
			    	selector: ".slides > .slideItem",
					slideshow: false,
					itemWidth: 50,
					itemMargin: 15,
					minItems: 1,
					maxItems: 1
	  			});
	  			$(".flexsliderBranche ol li a").each(function(index){
					$(this).attr("data-toggle",'popover');
					$(this).attr("data-placement",'bottom');
					$(this).attr("data-popover",'true');
					$(this).attr("data-content", $(".flexsliderBranche .slides .slideItem img").eq(index).attr("title"));	
				});
	  			$('.flexsliderProjecten').flexslider({
				    animation: "slide",
				    selector: ".projectSlides > .projectItem",
				    controlNav: false
				}); 
			});
			$window.resize(function() {
			    var gridSize = getGridSize();
			    flexslider.vars.minItems = gridSize;
			    flexslider.vars.maxItems = gridSize;
			});
		}());
	  },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
	      
	  },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.